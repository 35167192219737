@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: #352a1c;
	color: $white;

	contain: paint;

	min-height: 100svh;
	max-height: 100lvh;

	display: flex;

	align-items: center;

	&[data-centered='true'] {
		align-items: flex-start;
		justify-content: center;
		text-align: center;

		header > p {
			margin-inline: auto;
		}

		@include sm-down {
			text-align: center;

			align-items: center;
			flex-direction: column;
			justify-content: flex-start;

			padding-top: 10rem !important;
		}
	}

	position: relative;

	isolation: isolate;

	@include section-padding;

	padding-top: min(15rem, 20vh);

	@include sm-down {
		&[data-left-align='false'] {
			text-align: center;
			align-items: center;
		}

		&[data-left-align='true'] {
			header > p {
				margin-inline: 0;
			}
			align-items: flex-start;
		}

		flex-direction: column;
		justify-content: flex-start;

		padding-top: 8rem !important;
	}
}

.hero_header {
	max-width: 1440px;
	width: 100%;
	margin: 0 auto;
}

.container header > h1 {
	max-width: 13ch;
	margin-top: 1rem;
	@include sm-down {
		margin-inline: auto;
	}
}

.container header > p {
	max-width: 26ch;
	@include sm-down {
		margin-inline: auto;
	}
}

.container header > a {
	margin-top: 2.5rem;

	@include sm-down {
		margin-top: 1.5rem;
	}
}

.media_container {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	contain: paint;

	isolation: isolate;
}

.media_container_basic {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	padding-top: 70px;
	@include sm-down {
		padding-top: 54px;
	}

	contain: paint;

	isolation: isolate;

	&[data-shade='true'] {
		&::before {
			content: '';
			position: absolute;
			z-index: 1;
			inset: 0;
			background: linear-gradient(270deg, rgba(255, 255, 255, 0) -27.95%, #686767 100%);
			mix-blend-mode: multiply;
		}

		@include sm-down {
			&::before {
				background: linear-gradient(0deg, rgba(255, 255, 255, 0) -27.95%, #686767 100%);
				mix-blend-mode: multiply;
			}
		}
	}

	picture,
	video,
	img {
		object-fit: cover;
		height: 100%;
		width: 100%;

		@include sm-only {
			object-position: center 35%;
		}

		@include xs-only {
			object-position: center top;
		}
	}
}

.hero_image {
	z-index: -2;
}

.pedestal_layer {
	z-index: 1;
}

.hub_layer {
	z-index: 2;
}

.gift_1_wrapper {
	z-index: -1;
}

.gift_2_wrapper {
	z-index: 4;
}

.gift_1,
.gift_2,
.gift_3,
.hub_layer {
	img {
		transform: rotate(var(--gift-rotation)) translate3d(var(--gift-x), var(--gift-y), 0);
	}
}

.hub_layer {
	img {
		transform: translate3d(0, var(--gift-y), 0);
	}
}

$desktop-base-left-shift: 30rem;
$desktop-dynamic-left-shift: 16vw;

$pedestal-min-width: 600px;
$pedestal-max-width: 1762px;

.pedestal_layer {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	height: 100%;

	img {
		width: 100%;
		height: 100%;

		object-position: top left;
	}
}

.hub_layer {
	position: absolute;
	top: 0;
	left: 0;
	width: 33%;

	transform: translate(42%, -95%);
	img {
		width: 100%;
	}
}

.gift_1 {
	position: absolute;
	top: 0;
	left: 0;
	width: 30%;

	transform: translate(130%, -202%);

	img {
		width: 100%;
	}
}

.gift_2 {
	position: absolute;
	top: 0;
	left: 0;
	width: 24%;
	transform: translate(-24%, -102%);

	img {
		width: 100%;
	}
}

.gift_3 {
	position: absolute;
	top: 0;
	left: 0;
	width: 22%;
	transform: translate(-38%, -235%);

	img {
		width: 100%;
	}
}

.elements {
	position: absolute;
	bottom: 0;
	height: 35vh;
	max-height: 600px;

	left: calc($desktop-base-left-shift + $desktop-dynamic-left-shift);

	width: 60%;
	min-width: 800px;
	max-width: 1500px;

	> div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	@include sm-down {
		width: 100%;
		left: 0%;

		height: 22.5vh;

		min-width: unset;

		transform: translateX(20%);
	}
}

.cl_hero_text {
	height: 13rem;
	display: block;

	img {
		height: 100%;
	}

	@include sm-down {
		height: 7rem;

		width: 30rem;
	}
}

.cl_hero_image {
	img {
		object-position: 50% 10%;
	}
}

.cl_hero_eyebrow {
	max-width: 40ch !important;
	margin-top: 1.25rem;

	flex: 1;
}

.cl_hero_header {
	@include sm-down {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex: 1;
	}

	height: 100%;
}
