@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	background-color: #1d1c35;
	color: $white;

	min-height: 100svh;
	max-height: 100lvh;

	display: flex;
	align-items: center;
	position: relative;

	isolation: isolate;

	@include section-padding;

	@include sm-down {
		text-align: center;

		align-items: center;
		flex-direction: column;

		padding-top: 8rem !important;
	}
}

.container header > h1 {
	max-width: 14ch;
	margin-top: 1rem;
	@include sm-down {
		margin-inline: auto;
	}
}

.container header > p {
	max-width: 27ch;
	@include sm-down {
		margin-inline: auto;
	}
}

.container header > a {
	margin-top: 2.5rem;

	@include sm-down {
		margin-top: 1.5rem;
	}
}

.media_container {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	contain: paint;

	isolation: isolate;

	background-image: url('https://eightsleep.imgix.net/holiday-sale/Homepage+Header.png?auto=format');
	background-size: cover;
	background-position: center;
}

.hero_image {
	z-index: -4;
}

.pedestal_layer {
	z-index: -1;
}

.hub_layer {
	z-index: 2;
}

.gift_1_wrapper {
	z-index: -2;
}

.gift_2_wrapper {
	z-index: -6;
}

.gift_3_wrapper {
	z-index: -2;
}

.gift_1,
.gift_2,
.gift_3,
.hub_layer {
	img {
		transform: rotate(var(--gift-rotation)) translate3d(var(--gift-x), var(--gift-y), 0);
	}
}

.hub_layer {
	img {
		transform: translate3d(0, var(--gift-y), 0);
	}
}

$desktop-base-left-shift: 30rem;
$desktop-dynamic-left-shift: 16vw;

$pedestal-min-width: 600px;
$pedestal-max-width: 1762px;

.pedestal_layer {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	height: 100%;

	img {
		width: 100%;
		height: 100%;

		object-position: top left;
	}
}

.hub_layer {
	position: absolute;
	top: 0;
	left: 0;
	width: 33%;

	transform: translate(42%, -95%);
	img {
		width: 100%;
	}
}

.gift_1 {
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;

	transform: translate(45%, -180%);

	img {
		width: 100%;
	}
}

.gift_2 {
	position: absolute;
	top: 0;
	left: 0;
	width: 20%;
	transform: translate(-31%, -78%);

	img {
		width: 100%;
	}
}

.gift_3 {
	position: absolute;
	top: 0;
	left: 0;
	width: 36%;
	transform: translate(-46%, -154%);

	img {
		width: 100%;
	}
}

.elements {
	position: absolute;
	bottom: 0;
	height: 25svh;
	max-height: 600px;

	left: calc($desktop-base-left-shift + $desktop-dynamic-left-shift);

	width: 60%;
	min-width: 800px;
	max-width: 1500px;

	> div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	@include lg-up {
		height: 22svh;
	}

	@include xl-up {
		height: 10svh;
	}

	@include sm-down {
		width: 100%;
		left: 0%;

		height: 20svh;

		min-width: unset;

		transform: translateX(20%);
	}

	@include xs-only {
		height: 12.5svh;
	}
}

.video {
	object-position: 50% 66%;
}

.jan_image {
	img {
		object-position: 66% 50%;

		@include xs-only {
			object-position: 35% 0%;
		}
	}
}

.hero_control_image > img {
	@include md-up {
		object-position: 50% 70%;
	}
}

.hero_variant_image > img {
	@include md-up {
		object-position: 50% 70%;
	}
}
